import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const H1 = makeShortcode("H1");
const InclusionAndDiversity = makeShortcode("InclusionAndDiversity");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <H1 mdxType="H1">Inclusion and Diversity</H1>
    <p>{`At Fastly, we are committed to inclusion and diversity (I&D). We believe that the diversity of the global communities we serve should be reflected in everything we do if we want to make impactful differences for those communities, especially those that are disadvantaged or marginalized. The communities we serve have different perspectives and lived experiences that we can learn from. Understanding those perspectives will allow us to change how we think.`}</p>
    <p>{`We invite you to join the Fastly Documentation team in our annual goal to educate ourselves and share what we learn via links to people, holidays, and other significant dates that are important to the culture of our fellow Fastlyans and the communities they serve. Specifically, we want to:`}</p>
    <ul>
      <li parentName="ul">{`educate people on interesting aspects of the culture of fellow Fastlyans, especially during awareness months;`}</li>
      <li parentName="ul">{`highlight additional information about a holiday, day of recognition, or period of awareness that's important to us; and`}</li>
      <li parentName="ul">{`increase awareness around non-dominant and cultural holidays.`}</li>
    </ul>
    <p>{`Follow your curious spirit and click on these links when you notice them. Through education and increased awareness, we hope to create community-building opportunities that go beyond Fastly.`}</p>
    <InclusionAndDiversity mdxType="InclusionAndDiversity" />
    <h2 {...{
      "id": "all-highlights-for-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#all-highlights-for-2024",
        "aria-label": "all highlights for 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All highlights for 2024`}</h2>
    <h3 {...{
      "id": "september-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#september-2024",
        "aria-label": "september 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`September 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`September 18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🇲🇽 `}<a rel="nofollow" href="https://latina.com/krystal-puga-on-breaking-barriers-in-space-as-a-latina-engineer/">{`Krystal Puga`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Krystal Puga (she/her) is a Mexican engineer whose work includes systems engineering for the James Webb Space Telescope and Artemis missions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`September 25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🇨🇴 `}<a rel="nofollow" href="https://www.youtube.com/watch?v=FyjbF1CZrvY">{`Angela Guzman`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Angela Guzman (she/her) is a Colombian-American graphic designer best known for designing the original set of Apple Emoji as a design intern at Apple.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "august-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#august-2024",
        "aria-label": "august 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`August 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`August 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🐶 `}<a rel="nofollow" href="https://www.humanesociety.org/">{`DOGust`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The month of August is National Dog Month, and August 1 is the universal ceremonial birthday for shelter dogs when their actual birthday isn't known.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`August 12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🌎 `}<a rel="nofollow" href="https://www.un.org/en/observances/youth-day">{`International Youth Day`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`International Youth Day raises awareness for the cultural and legal issues that impact young people today.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "july-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#july-2024",
        "aria-label": "july 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`July 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`July 14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img src="/assets/images/decorators/non-binary-pride.png" alt="Image of the non-binary pride flag" style={{
              width: "30px",
              height: "30px"
            }} />{` `}<a rel="nofollow" href="https://transequality.org/issues/resources/understanding-nonbinary-people-how-to-be-respectful-and-supportive">{`International Non-binary People's Day`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`International Non-binary People's Day is observed on July 14th each year because it is the exact midpoint between international men's day and international women's day. It is aimed at raising awareness around issues faced by non-binary people around the world.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`July 21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🙏 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Guru_Purnima">{`Guru Purnima`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Guru Purnima is a religious festival celebrated in India, Nepal, and Bhutan and observes and honors one's chosen spiritual teachers and leaders.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "june-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#june-2024",
        "aria-label": "june 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`June 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`June 1-30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🌈 `}<a rel="nofollow" href="https://guides.loc.gov/lgbtq-studies/stonewall-era">{`LGBTQIA+ US Pride Month`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pride month honors the anniversary of the 1969 Stonewall rebellion and raises awareness of works that seek to achieve equal justice and opportunity for lesbian, gay, bisexual, transgender, and questioning Americans.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`June 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img src="/assets/images/decorators/tron-disc.png" alt="Icon of super soaker toy" style={{
              width: "30px",
              height: "30px"
            }} />{` `}<a rel="nofollow" href="https://music.youtube.com/playlist?list=OLAK5uy_kA227VFe1DyjEunbHErgcNftxORKz9yGQ">{`Wendy Carlos`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wendy Carlos (she/her) is a transgender American musician and composer best known for her electronic music and film scores. Within the tech community she is well known for composing the 1982 soundtrack to the original Tron movie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`June 12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🖨 `}<a rel="nofollow" href="https://guides.loc.gov/lgbtq-studies/stonewall-era">{`Jemma Redmond`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jemma Redmond (she/her) was a nano-bioscience engineer who pioneered the use of living cells as 3D printing materials as well as the robotic tooling required to ensure that the organic materials remained alive during the printing process. Up to her untimely death, she frequently spoke publicly about being intersex and about her desire to eventually be able to print a functioning uterus. Her discoveries form the foundation of current research in using bioprinted body parts in life-saving surgeries.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`June 19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💻 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Lynn_Conway">{`Lynn Conway`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lynn Conway (she/her) was a computer scientist, engineer, and transgender activist. Her pioneering research in the supercomputer industry formed the basis for creating modern, high-performance microprocessors. Without her work, the computer technology revolution of the 1980s would likely have been delayed by decades.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`June 26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🐪  `}<a rel="nofollow" href="https://www.youtube.com/watch?v=LscTx6DHh9I">{`Audrey Táng Fèng`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Audrey Táng Fèng (all pronouns) is a non-binary, free software programmer, former entrepreneur, and Taiwan's inaugural Digital Minister of Digital Affairs. She is also Taiwan's first transgender minister and the youngest minister in the country's history. She is considered a pioneer in Perl programming and currently advocates the use of digital social innovation to empower democracy.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "may-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#may-2024",
        "aria-label": "may 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`May 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`May 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💉 `}<a rel="nofollow" href="https://www.thelancet.com/journals/laninf/article/PIIS1473-3099(20)30645-9/fulltext">{`Wong Yee Ching`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wong Yee Ching (she/her) was a Chinese-American virologist and molecular biologist who discovered the link between HIV and AIDS and whose research provided the foundation for investigating treatments for emerging infectious diseases like COVID-19.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`May 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✡️ `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Yom_HaShoah">{`Yom HaShoah`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yom HaShoah, also known as Holocaust and Heroism Remembrance Day and International Holocaust Remembrance Day, is a day of commemoration for the approximately six million Jews murdered in the Holocaust by Nazi Germany and its collaborators, and for the Jewish resistance in that period.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`May 15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🦸 `}<a rel="nofollow" href="https://www.marvel.com/comics/creators/11841/sana_amanat">{`Sana Amanat`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sana Amanat (she/her) is an American comic book editor and an executive of production and development at Marvel Studios. She is known for co-creating Kamala Khan, the alter-ego of the super hero known as Ms. Marvel and the first Muslim-American superhero with a solo Marvel Comics series.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`May 22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪸 `}<a rel="nofollow" href="https://www.lowellmilkencenter.org/programs/projects/view/dr-isabella-abbott-first-lady-of-limu/hero">{`Dr. Isabella Kauakea Yau Yung Aiona Abbott`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dr. Isabella Kauakea Yau Yung Aiona Abbott (she/her) was the first Native Hawaiian woman to receive a PhD in science. Known as the “First Lady of Limu” (“limu” being the native Hawaiian word for “seaweed”), Abbott discovered more than 200 species of algae and is regarded as the world’s leading expert on algae in the Pacific Ocean basin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`May 29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🇵🇭 `}<a rel="nofollow" href="https://youtu.be/g5k9xKXy2Tw?si=8aQfvJuwKdb4O_xn">{`Aileen Judan-Jiao`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aileen Judan-Jiao (she/her) is the President and Country General Manager of IBM Philippines and the first homegrown Filipina leader of IBM Philippines. Through IBM’s SkillsBuild program, she is pushing to to upskill the Philippine workforce in artificial intelligence and other technologies through free online courses.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "april-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#april-2024",
        "aria-label": "april 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`April 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`April 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪶 `}<a rel="nofollow" href="https://youtu.be/aJ9sMoQ-CO8?si=JZi7XPDf333QUMqs">{`landless acknowledgment`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a rel="nofollow" href="https://www.nate-marshall.com/">{`Nate Marshall`}</a>{` (he/him) is an African-American writer, editor, educator, and MC. His poetry is inspired by the places that shaped him, especially the southside of Chicago where he grew up.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`April 9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪶 `}<a rel="nofollow" href="https://youtu.be/bERFxWEheX4?si=DrfUiVIpnm4_C61e">{`The City in Which I Love You`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Li-Young Lee (he/him) is a Chinese-American poet. His work is influenced by classical Chinese poets and known for its lyricism and use of silence.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`April 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪶 `}<a rel="nofollow" href="https://youtu.be/g3dXOIxsPg8?si=Bip3jcBFRgdS3foH">{`Mami Came to this Country as a Nanny`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Elizabeth Acevedo (she/her) is a Dominican-American poet and author of several young adult novels in verse. Her poetry and books are influenced by Dominican and New York culture.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`April 23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪶 `}<a rel="nofollow" href="https://www.youtube.com/watch?v=1u3o615KRog">{`Notebook Fragments`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ocean Vuong (he/him) is a Vietnamese American poet, essayist, and novelist. His poetry emphasizes the relationship between form and content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`April 30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🪶 `}<a rel="nofollow" href="https://www.youtube.com/watch?v=cezX7pg3dOI">{`Separation Wall`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Naomi Shihab Nye (she/her) is a Palestinian-American poet, editor, songwriter, and novelist. Her work has been described as, “international in scope and internal in focus.”`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "march-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#march-2024",
        "aria-label": "march 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`March 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`March 6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🇵🇸 `}<a rel="nofollow" href="https://www.youtube.com/watch?v=adnET4HZy9E">{`Abeer Abu Ghaith`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abeer Abu Ghaith (she/her) is a tech entrepreneur and social activist who is often called “Palestine's first female high-tech entrepreneur.” Born in a refugee camp in Jordan, she went on to found MENA Alliances, a business dedicated to connecting global companies with highly skilled, vetted talent from the MENA (Middle East and North Africa) region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`March 13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🩺 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Rachel_Levine">{`Dr. Rachel Levine`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dr. Rachel Levine (she/her) is the United State’s Assistant Secretary for Health and a four-star admiral in the U.S. Public Health Service Commissioned Corps. Along with being one of the few openly transgender U.S. government officials, she is the first openly transgender four-star officer of any of the nation’s uniformed services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`March 20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🚀 `}<a rel="nofollow" href="https://www.nasa.gov/centers-and-facilities/langley/katherine-johnson-biography/">{`Katherine Johnson`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Katherine Johnson (she/her) was an American mathematician whose calculations of orbital mechanics as a NASA employee were critical to the success of many space flights including the first orbital moon flight and Apollo 11.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`March 27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🇳🇬🇬🇧 `}<a rel="nofollow" href="https://aimafidon.com/about/">{`Anne-Marie Imafidon`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anne-Marie Imafidon (she/her) is the youngest girl ever to pass A-level computing (aged 11) and one of the youngest to achieve a Computer Science Masters from Oxford University. A true prodigy, she uses her platform to inspire and promote the next generation of women in STEM.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`March 31st`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🩵🩷🤍 `}<a rel="nofollow" href="https://www.glaad.org/tdov">{`International Transgender Day of Visibility`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Celebrate transgender people and raise awareness of discrimination faced by them worldwide.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "february-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#february-2024",
        "aria-label": "february 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`February 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`February 1-28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`❤️🖤💚 `}<a rel="nofollow" href="https://www.blackhistorymonth.gov/">{`US Black History Month`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`US Black History Month celebrates the people and events in the history of the African and Black diaspora.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`February 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img src="/assets/images/decorators/super-soaker.png" alt="Icon of super soaker toy" style={{
              width: "30px",
              height: "30px"
            }} />{` `}<a rel="nofollow" href="http://lonniejohnson.com/biography/">{`Lonnie G. Johnson`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lonnie Johnson (he/him) is an American inventor and aerospace engineer who holds more than 250 patents, among them the patent for the Super Soaker toy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`February 14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`📚 `}<a rel="nofollow" href="https://www.themorgan.org/belle-greene">{`Belle da Costa Greene`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Belle da Costa Greene (she/her) was the private librarian of J. Pierpont Morgan. She was responsible for acquiring countless significant collection items and also advocating for those items to be available to the public.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`February 21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👨🏿‍💻 `}<a rel="nofollow" href="https://www.wired.com/story/cryptoharlem-matt-mitchell-digital-stop-and-frisk/">{`Matt Mitchell`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Matt Mitchell (he/him) is a hacker and leader in the field of digital privacy and anti-surveillance advocacy. He also founded `}<a rel="nofollow" href="https://www.cryptoharlem.com/our-mission">{`CryptoHarlem`}</a>{`, a non-profit organization dedicated to cybersecurity education and advocacy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`February 28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👁️ `}<a rel="nofollow" href="https://lemelson.mit.edu/resources/marie-van-brittan-brown">{`Marie van Brittan Brown`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marie van Brittan Brown (she/her) was an American nurse and inventor of the first home security system. As the forerunner for modern home security camera and alarm systems, her invention continues to be cited in most home-security patent applications today.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "january-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#january-2024",
        "aria-label": "january 2024 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`January 2024`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Date`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Event or person`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`January 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`⚫️ `}<a rel="nofollow" href="https://www.un.org/en/observances/braille-day">{`World Braille Day`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`World Braille Day raises awareness of the importance of Braille, a tactile representation of alphabetic and numerical symbols, as a means of communication for blind and partially sighted people.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`January 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🫶🏿 `}<a rel="nofollow" href="https://dayofracialhealing.org/about-ndorh/">{`National Day of Racial Healing`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The National Day of Racial Healing is a time to contemplate our shared values and create the blueprint together for #HowWeHeal from the effects of racism. Launched in 2017, it is an opportunity to bring ALL people together in their common humanity and inspire collective action to create a more just and equitable world.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "past-highlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#past-highlights",
        "aria-label": "past highlights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Past highlights`}</h2>
    <details>
      <summary>All highlights for 2023</summary>
      <h3 {...{
        "id": "december-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#december-2023",
          "aria-label": "december 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`December 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`December 26-31`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img src="/assets/images/decorators/kwanzaa.png" alt="Kwanzaa candles" style={{
                width: "30px",
                height: "30px"
              }} />{` `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Kwanzaa">{`Kwanzaa`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Kwanzaa was created by American activist and scholar, `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Maulana_Karenga">{`Maulana Karenga`}</a>{`, as a specifically African-American holiday. Based on West and Southeast African harvest festivals and solstice traditions, Kwanzaa derives from the Swahili word phrase `}<em parentName="td">{`matunda ya kwanza`}</em>{`, meaning "first fruits."`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "november-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#november-2023",
          "aria-label": "november 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`November 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`November 1-30`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✍🏾 `}<a rel="nofollow" href="https://nanowrimo.org/impact">{`National Novel Writing Month`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`National Novel Writing Month, usually referred to by its shortened name NaNoWriMo, is an annual creating writing challenge that encourages and supports writers to "use their voices, achieve creative goals, and build new worlds." Each year during November, participants are encouraged to write the first draft of a 50,000-word manuscript in the space of a single month. Since its inception, a number of `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/National_Novel_Writing_Month#Published_NaNoWriMo_novels">{`notable novels`}</a>{` result from the annual event.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`November 19-21`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`⚧ `}<a rel="nofollow" href="https://glaad.org/tdor/">{`Trans Day of Remembrance`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Trans Day of Remembrance memorializes those who have been murdered as a result of transphobia and draws attention to the continued violence endured by transgender people.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "october-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#october-2023",
          "aria-label": "october 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`October 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`October 3-5`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☀️ `}<a rel="nofollow" href="https://www.chickasaw.tv/profiles/piomingo-profile">{`Piominko Day`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Piominko (he/him) was a pre-removal Chickasaw leader who signed the first Treaty of Hopewell, formalizing the tribe's alliance with the government of a very young United States that had formally declared their independence from the British Empire only 10 years prior.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`October 10-12`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`💀 `}<a rel="nofollow" href="https://es.wikipedia.org/wiki/Festividad_de_las_%C3%91atitas">{`Festividad de las Ñatitas`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Fiesta de la Ñatitas is a Bolivian holiday mixing Indigenous and Catholic traditions. Bolivians gather at cemeteries carrying skulls and then dance, sing, and pray around the city as a way of celebrating life and fertility and respecting and honoring the forgotten dead.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "september-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#september-2023",
          "aria-label": "september 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`September 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`September 12-14`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🇬🇹 `}<a rel="nofollow" href="https://www.nobelprize.org/prizes/peace/1992/tum/biographical/">{`Rigoberta Menchú Tum`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Rigoberta Menchú Tum is a K'iche' Guatemalan activist and winner of the Nobel Peace Prize. She is notable for her work to promote indigenous rights and ethno-cultural reconciliation.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`September 19-21`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🪳 `}<a rel="nofollow" href="https://americanhistory.si.edu/collections/object-groups/women-mathematicians/grace-hopper-the-navy-and-computers">{`Dr. Grace Hopper`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dr. Grace Hopper (she/her) was an American computer scientist and mathematician who served in the United States Navy. In the computing industry, she is well known for the discovery of a moth in a computer relay, leading to the terms "bug" and "debugging" becoming popular when describing computer malfunctions. The moth and the log book documenting its discovery are now located in the Smithsonian.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`September 26-28`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🇵🇷 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Felicitas_M%C3%A9ndez">{`Felicitas Mendezr`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Felicitas Mendez was a Puerto Rican American activist. Together with her husband, they filed a lawsuit in 1945 on behalf of around 5,000 Hispanic-American schoolchildren in the Orange County school system protesting segregation. Their landmark case paved the way for other desegregation cases including `}<em parentName="td">{`Brown v. Board of Education of Topeka`}</em>{`.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "august-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#august-2023",
          "aria-label": "august 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`August 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`August 6-9`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`☮️ `}<a rel="nofollow" href="https://theelders.org/programmes/remembering-hiroshima-and-nagasaki">{`Remembering Hiroshima and Nagasaki`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2023 commemorates the 78th anniversary of the `}<a rel="nofollow" href="https://theelders.org/programmes/remembering-hiroshima-and-nagasaki">{`atomic bombings of Hiroshima and Nagasaki, Japan`}</a>{` on August 6th and August 9th, 1945. The observance serves as a reminder of the disastrous effects of war and pays respect to the approximately 226,000 victims who died that day.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`August 17-19`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🥋🥇 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Kimia_Alizadeh">{`Kimia Alizadeh`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Kīmiyā Alīzādeh Zonūzī (Kimia Alizadeh, she/her) is the first Iranian woman to win a summer Olympics gold medal (Tae Kwan Do).`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "july-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#july-2023",
          "aria-label": "july 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`July 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`July 17-19`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><img src="/assets/images/decorators/mandela-day.png" alt="The logo for the Mandela Foundation" style={{
                width: "30px",
                height: "30px"
              }} />{` `}<a rel="nofollow" href="https://www.mandeladay.com/">{`Nelson Mandela Day`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Nelson Mandela Day honors the life and legacy of the anti-apartheid activist.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`July 26-27`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`♿ `}<a rel="nofollow" href="https://www.ada.gov/">{`Disability Pride Month`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Disability Pride Month commemorates the passing of the Americans with Disabilities Act, which was signed into law on July 26, 1990. This month aims to celebrate people with disabilities, honor and uphold their inherent dignity and inalienable rights, and promote their visibility and contributions.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "june-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#june-2023",
          "aria-label": "june 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`June 2023`}</h3>
      <p>{`To celebrate LGBTQIA+ Pride Month, we will highlight important LGBTQIA+ figures each week.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`June 1-30`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><a rel="nofollow" href="https://youth.gov/feature-article/june-lgbt-pride-month">{`U.S. LGBTQIA+ Pride Month`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`LGBTQIA+ Pride Month honors the anniversary of the 1969 Stonewall rebellion and raises awareness of works that seek to achieve equal justice and opportunity for lesbian, gay, bisexual, transgender, and questioning Americans.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`June 6-8`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🚀 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Sally_Ride">{`Dr. Sally Ride`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dr. Sally Ride (she/her) was the first American woman in space and is now recognized as the first acknowledged gay astronaut. Her sexual orientation was not discovered until after her death in 2012 when `}<a rel="nofollow" href="https://www.businesswire.com/news/home/20120723006436/en/Sally-Kristen-Ride-Ph.D.-Trailblazing-American-Woman">{`her obituary was posted`}</a>{` by Sally Ride Science, the educational organization she founded.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`June 13-15`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✒️ `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Audre_Lorde">{`Audrey Lorde`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Audrey Lorde (she/her) was (as quoted in Wikipedia) a self-described "black, lesbian, mother, warrior, poet." She is best known for the expression of emotions in her poetry that highlighted, confronted, and demanded solutions to rights surrounding racism, sexism, feminism, queer identity, illness, and disability.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`June 19-21`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`💙 `}<a rel="nofollow" href="https://www.un.org/en/observances/refugee-day">{`World Refugee Day`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`World Refugee Day, designated as such by the United Nations, aims to "build empathy and understanding for refugees around the world and recognize their resilience in rebuilding their lives."`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`June 27-29`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`♆ `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Geo_Soctomah_Neptune">{`Geo Soctomah Neptune`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Geo Soctomah Neptune (she/they) is an Indigenous artist who works with `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Passamaquoddy">{`Passamaquoddy`}</a>{` and `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Wabanaki_Confederacy">{`Wabanaki`}</a>{` youths to preserve the culture arts of their ancestors by teaching basket weaving in the state of Maine in the United States. In 2020, they were the first openly two-spirited person to be elected to public office in Maine. They champion social and political issues that impact Indigenous people through their activism.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "may-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#may-2023",
          "aria-label": "may 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`May 2023`}</h3>
      <p>{`To celebrate Asian American Pacific Islander Heritage Month, we will highlight an important figure of Asian American or Pacific Islander origin each week.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`May 9-11`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🐯 `}<a rel="nofollow" href="https://disabilityvisibilityproject.com/about/">{`Alice Wong`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Alice Wong (she/her) is a disabled activist and writer who founded the `}<a parentName="td" {...{
                "href": "https://disabilityvisibilityproject.com/"
              }}>{`Disability Visibility Project`}</a>{`, an online community dedicated to creating, sharing, and amplifying disability media and culture.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`May 16-18`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🍇 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Larry_Itliong">{`Larry Itliong`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Larry Itliong (he/him) was a Filipino American labor organizer who advocated for farm workers’ rights. One of his most notable and successful campaigns was the `}<a rel="nofollow" href="https://www.nps.gov/articles/000/workers-united-the-delano-grape-strike-and-boycott.htm">{`Delano Grape Strike`}</a>{`, which united thousands of Filipino American farm workers to strike for better wages and basic necessities like clean water and toilets.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`May 23-25`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`👩🏻‍🚀 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Ellison_Onizuka">{`Ellison Onizuka`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Ellison Onizuka (he/him) was the first Asian American and first person of Japanese origin to reach space. Sadly, he and his 6 crew mates lost their lives during the `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Space_Shuttle_Challenger_disaster">{`Space Shuttle Challenger disaster`}</a>{` in 1986.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`May 30-31`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🌉 `}<a rel="nofollow" href="https://youtu.be/ZpgZxyEmSy8">{`Terisa Siagatonu`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Terisa Siagatonu (she/they) is a Samoan queer poet, community leader, and mental health educator born and based in the San Francisco Bay area. Her poetry combines her intersectional experience with political and personal calls to action.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "april-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#april-2023",
          "aria-label": "april 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`April 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`April 6-8`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🇷🇼 `}<a rel="nofollow" href="https://www.unesco.org/en/days/tutsi-genocide">{`International Day of Reflection`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Observe and reflect on the 1994 genocide of the Tutsi in Rwanda that resulted in more than 1 million people dying in the short span of 100 days. April 7th marks the day the genocide started.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`April 24-28`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`💉 `}<a rel="nofollow" href="https://www.who.int/campaigns/world-immunization-week/">{`World Immunization Week`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`World Immunization Week, a campaign by the World Health Organization (WHO), aims to highlight the need and promote the use of vaccines to protect people across the globe from diseases.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "march-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#march-2023",
          "aria-label": "march 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`March 2023`}</h3>
      <p>{`The theme of 2023's Women's History month is "Celebrating Women Who Tell Our Stories." To celebrate Women’s History Month this year, we will highlight the stories and contributions of women that history has overlooked. As this is a declared month in the US, UK, and Australia, we've selected four women from around the globe who have devoted their lives to the pursuit of truth.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`March 7-9`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`⚖️ `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Ruth_Bader_Ginsburg">{`Joan Ruth Bader Ginsberg`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Joan Ruth Bader Ginsberg (she/her) was an associate justice on the Supreme Court of the United States until her recent death from cancer in 2020. She was the first Jewish woman and second woman overall to serve on the Court. Even before her career on the Court, her legal briefs and writings were instrumental in advocating for the protection of women and their equality.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`March 14-16`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🎶 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Miriam_Makeba">{`Zenzile Miriam Makeba`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Zenzile Miriam Makeba (she/her), noted singer and songwriter of `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Swazi_people">{`Swazi`}</a>{` and `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Xhosa_people">{`Xhosa`}</a>{` decent, used her musical talents to oppose the institutionalized segregation and white supremacy of the apartheid regime in South Africa. Her prominence as a political and cultural style icon contributed to the strengthening of `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Pan-Africanism">{`Pan-Africanism`}</a>{` throughout the world during her lifetime.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`March 21-23`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`📚 `}<a rel="nofollow" href="https://malala.org/malalas-story?sc=header">{`Malala Yousafzai`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`لالہ یوسفزئی (Malala Yousafzai, she/her) is the world's youngest Nobel Peace Prize laureate. She came to the world's attention through her activism in Pakistan, which started at the age of 11, where she spoke out on behalf of women and their right to education.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`March 28-30`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🇺🇦 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Olena_Shevchenko">{`Olena Olehivna Shevchenko`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Олена Олегівна Шевченко (Olena Olehivna Shevchenko, she/her), an activist for human rights in Ukraine, is known especially for her tireless work supporting women's rights and LGBTQIA+ rights. During the 2022 Russian invasion of Ukraine, she was instrumental in organizing safe havens and support for refugees fleeing the conflicts.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`March 30-31`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🏳️‍⚧️ `}<a rel="nofollow" href="https://www.glaad.org/tdov">{`International Transgender Day of Visibility`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Celebrate transgender people and raise awareness of discrimination faced by them worldwide.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "february-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#february-2023",
          "aria-label": "february 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`February 2023`}</h3>
      <p>{`To celebrate Black History Month, each week we will highlight the history, stories, and contributions of people across the African and Black diaspora.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`February 1-28`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><a rel="nofollow" href="https://en.wikipedia.org/wiki/Black_History_Month">{`U.S. Black History Month`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`US Black History Month celebrates the people and events in the history of the African and Black diaspora.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`February 7-9`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🚲 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Major_Taylor">{`Marshall Walter “Major” Taylor`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Major Taylor (he/him) is known as one of the fastest men ever to race on two wheels, an American who dominated sprint cycling in the late 1800s and early 1900s becoming the 2nd Black athlete to win a world championship in any sport. He did all this while battling bitter racial prejudice and physical violence from those competing against him.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`February 14-16`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🌼 🗞️ `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Daisy_Bates_(activist)">{`Daisy Gaston Bates`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Daisy Gaston Bates (she/her) was a civil rights activist and journalist who played a leading role in the `}<a parentName="td" {...{
                "href": "https://en.wikipedia.org/wiki/Little_Rock_Nine"
              }}>{`Little Rock Integration Crisis of 1957`}</a>{`, when a group of nine African American students enrolled in a previously all-white high school in Arkansas after the US Supreme Court declared segregated schools unconstitutional. Bates believed that her greatest contribution to this seminal moment in American Civil Rights history was ensuring that none of the children participating in the desegregation activities were physically harmed, even during the most turbulent years of her advocacy.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`February 21-23`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🩸 `}<a rel="nofollow" href="https://en.wikipedia.org/wiki/Charles_R._Drew">{`Charles Richard Drew`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Doctor Charles Richard Drew (he/him) helped develop America’s first large-scale blood banking program in the 1940s, earning him accolades as “the father of the blood bank." In 1942, he resigned from his position as the first director of the American Red Cross due to their policies of segregating African-American blood donations, a practice that continued well into the 1950s.`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "january-2023",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#january-2023",
          "aria-label": "january 2023 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`January 2023`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Date`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Event or person`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`January 2-6`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`🅾️ `}<a rel="nofollow" href="https://www.redcross.org/about-us/news-and-events/news/2021/help-save-lives-during-national-blood-donor-month.html">{`Start of National Blood Donor Month`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`In 1969, President Richard Nixon (he/him) proclaimed January as national blood donor month in the U.S., both to celebrate those everyday heroes who donate blood and encourage blood donation during the winter months, one of the most difficult times to maintain a sufficient blood supply.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`January 10-12`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`⛓️ `}<a rel="nofollow" href="https://www.whitehouse.gov/briefing-room/statements-releases/2023/02/13/fact-sheet-presidents-interagency-task-force-to-monitor-and-combat-trafficking-in-persons-2/">{`National Human Trafficking Awareness Day`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`January 10 marks National Human Trafficking Awareness Day. Human trafficking is the third largest crime industry in the world despite being condemned as a gross violation of human rights by international conventions. Anyone can join the fight against human trafficking.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`January 26-28`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`✡️ `}<a rel="nofollow" href="https://www.un.org/en/outreach-programme-holocaust/2023">{`International Holocaust Remembrance Day`}</a></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`International Holocaust Remembrance honors the memory of the six million Jews and millions of other victims killed in the Holocaust by the Nazi regime and its collaborators. This day marks the anniversary of the liberation of the Nazi Concentration and Extermination Camp of Auschwitz-Birkenau.`}</td>
          </tr>
        </tbody>
      </table>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      